import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ClassPicker from './components/ClassPicker';
import SubjectPicker from './components/SubjectPicker';
import TopicPicker from './components/TopicPicker';

import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import ModulePage from './components/ModulePage';
import './App.css';
import { useDispatch } from 'react-redux';
import { setUserId } from './redux/actions/dataActions';
import ModulePageCum from './components/ModulePageCum';
// import Navbar from './components/Navbar';

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get('userId');
        if (userId) {
            console.log("Received userId:", userId);
            dispatch(setUserId(userId));
        }
    }, []);
    

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<ClassPicker />} />
                    <Route path="/picksubject" element={<SubjectPicker/>} />
                    <Route path="/picktopic" element={<TopicPicker/>} />
                    <Route path="/sidebar" element={<Sidebar />} />
                    <Route path="/maincontent" element={<MainContent />} />
                    <Route path="/module/:module" element={<ModulePage />} />
                    <Route path="/cummulative" element={<ModulePageCum />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
