import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClassOption from './ClassOption';
import { setLevel } from '../redux/actions/dataActions';
import '../styles/index.css';
import axios from 'axios';
import Navbar from './Navbar';
import { updateStreak } from '../redux/actions/progressActions';

const ClassPicker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [levels, setLevels] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const userId = useSelector((state)=>state.data.userId);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const taskResponse = await axios.get(`${process.env.REACT_APP_BE_URL}/task`);
                const uniqueLevels = [...new Set(taskResponse.data.map((item) => item.level))];
                setLevels(uniqueLevels);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
    
            try {
                const progressResponse = await axios.get(`${process.env.REACT_APP_BE_URL}/progress`, {
                    params: { userId: userId },
                });
                if (!progressResponse.data[0].streak) progressResponse.data[0].streak=0;
                if (!progressResponse.data[0].maxStreak) progressResponse.data[0].maxStreak=0;
                if (!progressResponse.data[0].lastStreakDate) progressResponse.data[0].lastStreakDate=new Date("1970-01-01T00:00:00Z");
                dispatch(updateStreak(progressResponse.data[0]));
                // console.log(progressResponse.data[0]);
            } catch (error) {
                console.error('Error fetching progress:', error);
            }
        };
    
        fetchData();
    }, [userId, dispatch]);
    

    const handleSelect = (label) => {
        if (selectedLevel === label) {
            // If the same item is clicked, unselect it
            setSelectedLevel(null);
            dispatch(setLevel(null));
        } else {
            setSelectedLevel(label);
            dispatch(setLevel(label));
            navigate('/picksubject');
        }
    };

    const goToSubjectPicker = () => {
        if (selectedLevel) {
            navigate('/picksubject');
        } else {
            alert('Please choose one');
        }
    };

    return (
        <>
            <Navbar />
            <div className="p-5 rounded-lg shadow-lg max-w-md mt-40 mx-auto text-center font-semibold bg-gray-1000 text-[white]">
                <h3 className="mb-5 text-xl">Pick a Level</h3>
                {levels.map((level, index) => (
                    <ClassOption
                        key={index}
                        label={level}
                        isSelected={selectedLevel === level}
                        onSelect={() => handleSelect(level)}
                    />
                ))}
                {/* {levels.length > 0 && (
                    <button
                        onClick={goToSubjectPicker}
                        className="bg-green-500 text-white border-none py-2 px-4 mt-5 cursor-pointer rounded-lg text-md hover:bg-green-600"
                    >
                        Set Level
                    </button>
                )} */}
            </div>
        </>
    );
};

export default ClassPicker;
