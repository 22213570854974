// progressReducer.js
import { SET_PROGRESS, UPDATE_STREAK, RESET_PROGRESS } from '../actions/progressActions';

const initialState = {
    subTopicsCovered: {},
    // streak: 0 || localStorage.getItem('streak'),
    // maxStreak: 0 || localStorage.getItem('maxStreak'),
    // lastStreakDate: null || localStorage.getItem('lastStreakDate'),
    streak: 0 ,
    maxStreak: 0 ,
    lastStreakDate: "1970-01-01T00:00:00Z" ,
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROGRESS:
            return {
                ...state,
                ...action.payload,
            };
        case UPDATE_STREAK:
        //     localStorage.setItem('streak', action.payload.streak);
        //     localStorage.setItem('maxStreak', Math.max(state.maxStreak, action.payload.streak));
            return {
                ...state,
                streak: action.payload.streak,
                maxStreak: Math.max(state.maxStreak, action.payload.streak),
                lastStreakDate: action.payload.lastStreakDate,
            };
        case RESET_PROGRESS:
            return initialState; 
        default:
            return state;
    }
};

export default progressReducer;
